import { addListToDropdown, Collection, createDropdown, icons, Plugin, ViewModel } from 'ckeditor5';
export class InsertEnvoriaTable extends Plugin {
   constructor(editor) {
      super(editor);
   }

   init() {
      this.editor.ui.componentFactory.add('insertEnvoriaTable', (locale) => {
         const items = new Collection();

         const tables = this.editor.config.get('envoriaTables.tables') ?? [];

         items.addMany(
            tables.map((table) => {
               return {
                  type: 'button',
                  model: new ViewModel({
                     withText: true,
                     label: table.label,
                     id: table.id,
                  }),
               };
            })
         );

         const dropdown = createDropdown(locale);

         dropdown.buttonView.set({
            icon: icons.table,
         });

         addListToDropdown(dropdown, items);

         dropdown.on('execute', (evt) => {
            const id = evt.source.id;

            if (id) {
               const table = tables.find(({ id: tableId }) => tableId === id);

               if (table) {
                  this.editor.model.change((writer) => {
                     this.editor.execute('insertTable', {
                        headingRows: 1,
                        headingColumns: 1,
                        rows: table.rows.length,
                        columns: table.columns.length + 1,
                     });

                     const tableModel = this.editor.model.document.selection
                        .getFirstPosition()
                        .getAncestors()
                        .find((item) => item.name == 'table');

                     const tableWalker = this.editor.plugins.get('TableUtils').createTableWalker(tableModel);

                     for (const slot of tableWalker) {
                        const rangeWrappingCellContent = writer.createRangeIn(slot.cell);

                        if (rangeWrappingCellContent) {
                           const sel = writer.createSelection(rangeWrappingCellContent);

                           // I'm using `insertContent()` instead of `writer.insertText()` because it's
                           // more future-proof (https://github.com/ckeditor/ckeditor5-table/issues/114).
                           // Also, we use a <tableCell>[...cell content...]</tableCell> to make sure we
                           // override the entire content (there can be an empty paragraph since ckeditor5@12.0.0).
                           // or, if `content` contains model docfrags/items:
                           // editor.model.insertContent( cellContent, sel );
                           if (slot.row === 0 && slot.column === 0) {
                              if (table.columnName) {
                                 this.editor.model.insertContent(writer.createText(table.columnName), sel);
                              }
                           } else if (slot.row === 0 && slot.column > 0) {
                              if (table.columns[slot.column - 1]) {
                                 this.editor.model.insertContent(writer.createText(table.columns[slot.column - 1].name), sel);
                              }
                           } else if (slot.column === 0 && slot.row > 0) {
                              if (table.rows[slot.row - 1]) {
                                 this.editor.model.insertContent(writer.createText(table.rows[slot.row - 1].name), sel);
                              }
                           } else if (slot.row !== 0 && slot.column !== 0) {
                              if (table.rows[slot.row - 1] && table.columns[slot.column - 1]) {
                                 this.editor.model.insertContent(
                                    writer.createElement('mergeField', {
                                       id: `${table.rows[slot.row - 1].identifier}_${table.columns[slot.column - 1].identifier}`,
                                    }),
                                    sel
                                 );
                              }
                           }
                        }
                     }
                  });
               }
            }
         });

         return dropdown;
         // Will render a dropdown with a list in the panel containing two items.
      });

      console.log('Envoria Table plugin has been registered');
   }
}
